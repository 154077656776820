import React from "react"
import EmailListForm from "./emailListForm"

const Bio = () => {
  return (
    <div className="max-w-2xl mx-auto mt-8">
      <h2 className="text-2xl italic">Enjoy the Article?</h2>
      <p>
        Feel free to check out what I am{" "}
        <a
          href="https://www.notion.so/Read-ing-List-96d87fa62d584af2bf68ea5bc9c6e215"
          className="italic font-bold text-blue-400 underline"
          to="/"
        >
          reading
        </a>{" "}
        right now or visit my public{" "}
        <a
          href="https://www.notion.so/justinliwag/Public-External-Brain-93a46459b77c4a6fa5d23bbfa1e97957"
          className="italic font-bold text-blue-400 underline"
        >
          External Brain
        </a>
        . This is where I store useful or interesting links that I read or come
        across.
      </p>
      <EmailListForm />
    </div>
  )
}

export default Bio
